<template>
	<div class="competing">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'competing'
	}
</script>

<style scoped>
.competing{
	padding-bottom: 20px;
}
</style>
